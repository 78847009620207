<template>
  <dash-page-new
    :root="$t('webinar.main.header')"
    :title="$t('webinar.main.header')"
    :filters="!SM ? webinarFilters : false"
    :filters-input="filtersData"
    @filter="filtersData = $event"
    :no-content-condition="isNoWebinarsExist"
    :loading="loading"
  >

    <template #no-content>
      <div
          class="text-center"
          style="padding-top: 200px; max-width: 530px; overflow: hidden; margin: auto"
      >
        <img
            src="@/modules/webinars/assets/main.png"
            :style="`height: 300px`"
            alt="no content image"
            class="mb-2"
        >
        <h3 class="mb-2" >{{ $t('webinar.main.label') }}</h3>
        <p style="font-size: 13px; font-weight: 400" class="mb-6">{{ $t('webinar.main.subtitle') }}</p>

        <ws-button
            outlined
            left-icon="mdi-video"
            :label="$t('webinar.main.create_webinar')"
        />
      </div>
    </template>

    <template #header_action >
      <v-sheet
        class="d-flex align-center ga-6 "
        :class="[
          { 'justify-start' : SM },
          { 'justify-center' : !SM },
          { 'pt-2' : SM }
        ]"
      >
        <ws-button
            :label="$t('webinar.main.add')"
            left-icon="mdi-plus"
            @click="openCreateModalWindow"
            :class="SM ? 'mr-0' : 'mr-2'"
        />
        <div v-if="SM" style="width: 40px; height: 40px" :class="SM ? 'mx-2' : 'my-0'" >
          <ws-search-filters class="filter-sm"  style="height: 100%"  :items="webinarFilters" />
        </div>


        <ws-tooltip :text="$t('webinar.main.tutorial_label')" >
          <ft-select
              @expand="selectedItem = item"
              :items="tutorialItemsSelect"
              left-icons
          >
            <v-btn icon class="rounded pa-5"><v-icon >mdi-book-open-blank-variant</v-icon></v-btn>
          </ft-select>
        </ws-tooltip>
      </v-sheet>
    </template>

    <template #default>

      <div class="d-flex flex-row py-6  align-stretch" style="height: 100%">

        <!--  Categories table -->
        <v-sheet
          class="overflow-hidden mr-4 rounded"
          style="border: 1px solid var(--wsBACKGROUND);  transition: width 0.3s ease;"
          :style="`width : ${collapseLists ? '47' : '250'}px`"
          :min-width="collapseLists ? '47' : '250'"
        >
          <!--     Category table     -->
          <v-sheet v-if="!collapseLists" class="d-flex align-center justify-space-between px-2" :color="wsLIGHTCARD" height="47px">

            <div  class="d-flex align-center" >
              <v-btn @click="openAddList" class="mr-3" icon>
                <v-icon :color="wsACCENT" >mdi-folder-plus-outline</v-icon>
              </v-btn>
              <h5 class="wsACCENT">{{ $t('webinar.main.category.name') }}</h5>
            </div>

            <v-btn @click="handleCollapse" icon >
              <v-icon :color="wsACCENT" >mdi-arrow-collapse-left </v-icon>
            </v-btn>

          </v-sheet>

          <v-sheet v-else class="d-block px-1" style="background-color: white; width: 478px;">

            <v-btn @click="openAddList" class="mb-2 d-block" icon>
              <v-icon  :color="wsACCENT" >mdi-folder-plus-outline</v-icon>
            </v-btn>

            <v-btn @click="handleCollapse" icon class="d-block">
              <v-icon :color="wsACCENT" >mdi-arrow-collapse-right</v-icon>
            </v-btn>
          </v-sheet>

          <ws-data-table
            v-if="!collapseLists"
            :items="categoryFiltered"
            :headers="categoryHeaders"
            :selected-row="selectedCategory"
            no-footer
            no-header
            disable-pagination
            style="border-top: 1px solid var(--wsBACKGROUND); border-bottom: 1px solid var(--wsBACKGROUND);"
            :row-action="(item) => { selectedCategory = item.value; }"
          >

            <template #item.text="{item,hover}">
              <div class="d-flex align-center justify-space-between" >
                <h5 class="wsACCENT shorten-text" style="min-width: 170px; font-size: 12px">{{ item.text }}</h5>
                <h5 v-if="!hover || !item.value || item.value === 'no_list' " class="wsDARKER">{{ item.webinars }}</h5>
                <h5 v-if="hover && item.value === 'all'" >{{ item.webinars }}</h5>
                <v-btn v-if="hover && item.value !== 'all' && !!item.value && item.value !== 'no_list'" @click="openEditWebinarsCategory(item)" class="mr-n2" small  icon>
                  <v-icon size="18" :color="wsACCENT">mdi-pencil</v-icon>
                </v-btn>

              </div>
            </template>
          </ws-data-table>

        </v-sheet>

        <!--  Webinars table -->
        <v-sheet  class="flex-grow-1 rounded overflow-x-auto"  style="position : relative">

          <div class="d-flex flex-column fill-height  wsRoundedLight" style="border : 1px solid var(--wsBACKGROUND)">
            <ws-data-table
                :headers="headers"
                :items="itemsFiltered"
                :search="filtersData.search || null"
                selectable
                :selected-rows="selectedWebinars"
                @select="selectedWebinars = $event.items"
                :multiple-actions="multipleActionsSelect"
                bordered
                :row-action="(item) => { item.status !== 'draft' ? $router.push(`webinar/${item.uuid}`) : openDraftModalWindow(item) }"
                footer-portal="webinars"
            >

              <template #item.name="{item}">
                <div style="padding: 11px 0; min-width: 270px">
                  <h5 :style="`font-size: 12px; color: ${wsACCENT}`" class="mb-1"> {{ item.name }} </h5>
                  <h5 :style="`color: ${wsDARKLIGHT}`" class="mb-1">  {{ !item.paid ? $t('webinar.main.dialog.free') : `${item.price} ${BUSINESS_CURRENCY}` }} </h5>
                  <h5 :style="`color: ${wsDARKLIGHT}`" > {{ MONTH_DAY_TIME(item.date_start) }} </h5>
                </div>
              </template>

              <template #item.accessed="{item}">
                <h5 v-if="item.participants"> {{ item.accessed }} ( {{ getAccessedPercent(item) }} %)</h5>
                <h5 v-else> 0 </h5>
              </template>

              <template #item.status="{item}">
                <h5
                    class="px-2 py-1 rounded-xl"
                    :style="`border: 1px solid ${ returnColor(item) }; width: fit-content; font-size: 12px; color: ${ returnColor(item) }`"
                >
                  {{ $t(`webinar.main.${item.status}`) }}
                </h5>
              </template>

              <template #item.options="{item}">
                <ft-select
                    @expand="selectedItem = item"
                    :items="editActionsSorted(item)"
                >
                  <v-btn icon><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                </ft-select>
              </template>

            </ws-data-table>
            <div class="flex-grow-1"></div>
            <div class="py-3" style="border-top : 1px solid var(--wsBACKGROUND)">
              <portal-target name="webinars" class=""></portal-target>
            </div>


          </div>





        </v-sheet>

      </div>

    </template>

    <template #dialog>
      <!-- Add Webinar dialog -->
      <webinar-settings
        :is-new-webinar="isNewWebinar"
        :uuid="selectedWebinar.uuid"
        :step="step"
        @set-step="updateStepValue"
        :display-dialog="displayDialog"
        @set-display-dialog="displayDialog = value"
        @add-new-webinar="addWebinar"
        @edit-webinar="editWebinar"
      />

      <!--  Add Webinar to category  -->
      <ws-dialog
        v-model="displayAddToCategoryDialog"
        :title="newCategory ? $t('webinar.main.category.add_to') : $t('webinar.main.category.remove_from')"
        :save-text="newCategory ? $t('Save') : $t('webinar.main.category.remove')"
        @save="newCategory ? addWebinarsToCategory() : removeCategoryFromWebinar()"
      >
        <ws-select
          v-if="newCategory"
          v-model="categorySelectEntityData"
          :items="categorySelect"
          :label="$t('webinar.main.dialog.list_category')"
          :placeholder="$t('webinar.main.dialog.check_category')"
          autocomplete
        />
        <h4 v-else class="text-left font-weight-medium ">{{ $t('webinar.main.category.remove_confirm_text') }}</h4>

      </ws-dialog>

      <!-- Category list dialog -->
      <ws-dialog
        v-if="displayListDialog"
        v-model="displayListDialog"
        @save="addEditWebinarsCategory"
        :title="$t(`webinar.main.category.${newList ? 'add' : 'edit'}`)"
        :save-text="newList ? $t('Add') : $t('Save')"
        :show-delete="!newList && listEntityData.value !== 'no_list'"
        @delete="deleteWebinarsCategory"
      >
        <ws-text-field
          v-model="listEntityData.text"
          :label="$t('Name')"
          :placeholder="$t('EnterName')"
        />

      </ws-dialog>

      <!-- Confirm delete webinar dialog -->
      <ws-dialog
        v-model="displayConfirmDeleteDialog"
        :title="$t(`webinar.main.delete`)"
        :save-text="$t(`Delete`)"
        @save="deleteWebinar(selectedItem)"
        @cancel="!displayConfirmDeleteDialog"
      >
        <h4  class="text-left font-weight-medium">
          {{ confirmDeleteDialogText }}
          <a
            v-if="selectedItem.status === 'finished'"
            href="#"
            :style="`color: ${wsATTENTION}; text-decoration: none`"
          >
            {{ $t('webinar.main.dialog.store_link') }}
          </a>
        </h4>
        <template #buttons v-if="selectedItem.status === 'finished'">
          <div>
            <ws-button :label="$t('Cancel')" outlined class="mr-2" @click="displayConfirmDeleteDialog = false" />
            <ws-button :label="$t(`webinar.main.dialog.delete_with_rec`)" class="mr-2" />
            <ws-button :label="$t(`webinar.main.dialog.delete_only_webinar`)" @click="deleteWebinar(selectedItem)"/>
          </div>

        </template>
      </ws-dialog>

      <!-- Stop broadcast webinar dialog -->
      <ws-dialog
        v-model="displayStopBroadcastDialog"
        :title="$t(`webinar.main.dialog.stop_broadcast_dialog_title`)"
        :save-text="$t('webinar.main.dialog.stop_broadcast_button')"
        @cancel="!displayStopBroadcastDialog"
      >
        <h4 class="text-left font-weight-medium ">{{ $t(`webinar.main.dialog.stop_broadcast_dialog_text`)}}</h4>
      </ws-dialog>

      <!-- Webinar window dialog -->
      <webinar-window
        v-if="displayWebinarWindowDialog"
        :current-webinar="webinarWindowEntityData"
        :uuid="webinarWindowEntityData.uuid"
        :display-webinar-window-dialog="displayWebinarWindowDialog"
        @set-display="displayWebinarWindowDialog = value"
      />

    </template>

  </dash-page-new>
</template>

<script>
  import { mapActions } from 'vuex'
  import WebinarWindow from "@modules/webinars/components/Webinar/admin/room/WebinarWindow.vue"
  import WebinarSettings from "@modules/webinars/components/Webinar/WebinarSettingsDialog.vue"
  import wsSearchFilters from "@/components/pages/dashboard/UI/wsSearchFilters.vue";
  export default {
    name: "Webinars",
    components: {
      wsSearchFilters,
      WebinarSettings,
      WebinarWindow,
    },
    data() {
      return {
        isNewWebinar: true,
        step: 1,
        entityData: {
          paid: false,
          access: true,
          scripts: {
            status: false,
            content: [{ text: ``, duration: 10 }]
          },
          time_before: 15,
          lang: 'ua',
          show_participants: false,
          status: 'draft'
        },
        loading: false,
        displayStopBroadcastDialog: false,
        displayConfirmDeleteDialog: false,
        displayAddToCategoryDialog: false,
        displayWebinarWindowDialog: false,
        displayDialog: false,
        itemDraft: {},
        filtersData: {},
        selectedItem: {},
        listEntityData: {},
        webinarWindowEntityData: {},
        webinarItems: [],
        selectedWebinars : [],
        collapseLists: false,
        categoryHeaders : [{value : 'text'}],
        selectedCategory : 'all',
        newList : false,
        newCategory: false,
        displayListDialog: false,
        categorySelect: [],
        managersList: [],
        categorySelectEntityData: '',
        selectedWebinar: {}
      }
    },

    computed: {
      // изменение содержимого диалогового окна с подтверждением удаления в зависимости от статуса вебинара
      confirmDeleteDialogText() {
        return this.selectedItem.status === 'finished'
            ? this.$t('webinar.main.dialog.confirm_delete_finished_text')
            : this.$t('webinar.main.dialog.confirm_delete_text')
      },
      categoryFiltered() {
        let items = this.COPY(this.categorySelect)
        items.unshift({ text : this.$t('webinar.main.table.all') , value : 'all' , webinars : this.categorySelect.total || this.webinarItems.length } )
        return items
      },
      itemsFiltered() {
        let items = this.webinarItems;

        // фильтр по статусу
        if (this.filtersData.status) {
          items = items.filter(el => el.status === this.filtersData.status);
        }

        // фильтр по оплате - платный/бесплатный
        if (this.filtersData.payment !== undefined) {
          items = items.filter(el => this.filtersData.payment ? el.paid : !el.paid);
        }

        // фильтр по менеджеру
        if (this.filtersData.owner) {
          items = items.filter(el => this.filtersData.owner === el.owner);
        }

        // фильтр по категориям
        if ( this.selectedCategory && this.selectedCategory !== 'all' ) {
          items = items.filter(el => el.categories.includes(this.selectedCategory));
        }
        return items
      },
      isNoWebinarsExist() {
        return this.webinarItems.length === 0;
      },
      paymentOptionsSelect() {
        return [
          { text: this.$t('webinar.main.dialog.free') , value: false },
          { text: this.$t('webinar.main.dialog.paid') , value: true },
        ]
      },
      webinarStatusSelect() {
        return [
          { text: this.$t('webinar.main.draft'), value: 'draft' },
          { text: this.$t('webinar.main.planned'), value: 'planned' },
          { text: this.$t('webinar.main.in_process'), value: 'in_process' },
          { text: this.$t('webinar.main.finished'), value: 'finished' },
        ]
      },
      tutorialItemsSelect() {
        return [
          { text: this.$t('webinar.main.tutorial_watch'), value: 'video-tutorial', icon: 'mdi-video'},
          { text: this.$t('webinar.main.create_webinar'), value: 'read-tutorial', icon: 'mdi-book-variant'},
        ]
      },
      headers() {
        return [
          { text: this.$t('webinar.main.table.name'), value: 'name' },
          { text: this.$t('webinar.main.table.status'), value: 'status' },
          { text: this.$t('webinar.main.table.participants'), value: 'participants' },
          { text: this.$t('webinar.main.table.accessed'), value: 'accessed' },
          { text: this.$t('webinar.main.table.joined'), value: 'joined' },
          { text: this.$t('webinar.main.table.absent'), value: 'absent' },
          { text: '', value: 'options', sortable: false, width: 10 },
        ]
      },
      editActions() {
        return [
          {
            text : this.$t('webinar.main.table.stop_broadcast'),
            value : 'stop_broadcast',
            icon: 'mdi-stop',
            belongs: ['in_process'],
            action : () => { this.displayStopBroadcastDialog = true;}
          },
          {
            text : this.$t('webinar.main.table.open_webinar'),
            value : 'open_webinar',
            icon: 'mdi-laptop-account',
            belongs: ['in_process', 'planned'],
            action : () => { this.openWebinarWindowDialog(this.selectedItem) }
          },
          {
            text : this.$t('webinar.main.table.statistic'),
            value : 'statistic',
            icon: 'mdi-information-outline',
            belongs: ['in_process', 'planned', 'finished'],
            action : () => { this.showWebinarStatistic(this.selectedItem) }
          },
          {
            text : this.$t('webinar.main.table.stop_publishing'),
            value : 'stop_publishing',
            icon: 'mdi-eye-off',
            belongs: ['planned'],
            // action : () => {  }
          },

          {
            text : this.$t('webinar.main.table.landing'),
            value : 'landing',
            icon: 'mdi-open-in-new',
            belongs: ['in_process', 'planned'],
            // action : () => {  }
          },
          {
            text : this.$t('webinar.main.table.change_category'),
            value : 'change_category',
            icon: 'mdi-folder-arrow-right-outline',
            belongs: ['in_process', 'planned', 'finished', 'draft'],
            action : () => { this.editWebinarCategory(this.selectedItem) }
          },
          {
            text : this.$t('Edit'),
            value : 'edit',
            icon: 'mdi-pencil',
            belongs: ['in_process', 'planned', 'draft'],
            action : () => { this.openDraftModalWindow(this.selectedItem) }
          },
          {
            text : this.$t('webinar.main.table.copy'),
            value : 'copy',
            icon: 'mdi-content-copy',
            belongs: ['in_process', 'planned', 'finished', 'draft'],
            action : () => { this.copyWebinar(this.selectedItem) }
          },
          {
            text : this.$t('Delete'),
            value : 'delete',
            icon: 'mdi-delete-outline',
            belongs: ['planned', 'finished', 'draft'],
            action : () => { this.displayConfirmDeleteDialog = true; }
          }
        ]
      },
      webinarFilters() {
        return [
          {
            text: this.$t('webinar.main.table.status'),
            value: 'status',
            type: 'select',
            items: this.webinarStatusSelect
          },
          {
            text: this.$t('webinar.main.table.payment'),
            value: 'payment',
            type: 'select',
            items: this.paymentOptionsSelect
          },
          {
            text: this.$t('webinar.main.table.owner'),
            value: 'owner',
            type: 'select',
            items: this.managersList
          },
        ]
      },
      multipleActionsSelect() {
        let items = [
          {
            text: 'webinar.main.category.add_to',
            icon: 'mdi-folder-outline',
            action: this.openMoveToCategory
          },
        ]
        if (this.categorySelect.length > 0 && !['all', 'no_list'].includes(this.selectedCategory)) {
          items.push({
            text: 'webinar.main.category.remove_from',
            icon: 'mdi-delete-outline',
            action: this.openRemoveFromCategory
          })
        }
        return items
      },
    },

    methods: {
      ...mapActions('webinars', ['GET_WEBINARS', 'DELETE_WEBINARS', 'ADD_WEBINARS_CATEGORY', 'GET_WEBINARS_CATEGORIES',
        'DELETE_WEBINARS_CATEGORY', 'EDIT_WEBINARS_CATEGORY', 'ADD_WEBINARS_TO_CATEGORY', 'DELETE_CATEGORY_FROM_WEBINAR', 'GET_ALL_MANAGERS',
        'COPY_WEBINAR']),

      editWebinarCategory() {

      },

      showWebinarStatistic(value) {
        this.$router.push(`webinar/${value.uuid}`)
      },

      updateStepValue(value) {
        this.step = value;
      },
      handleCollapse() {
        this.collapseLists = !this.collapseLists
      },

      openMoveToCategory() {
        this.newCategory = true;
        this.displayAddToCategoryDialog = true;
      },
      openRemoveFromCategory() {
        this.newCategory = false;
        this.displayAddToCategoryDialog = true;
      },
      openWebinarWindowDialog(item) {
        this.webinarWindowEntityData = item;
        this.displayWebinarWindowDialog = true
      },
      openAddList() {
        this.newList = true
        this.listEntityData = {}
        this.displayListDialog = true
      },
      openEditWebinarsCategory(item) {
        this.newList = false
        this.listEntityData = this.COPY(item)
        this.displayListDialog = true
      },
      openCreateModalWindow() {
        this.isNewWebinar = true;
        this.displayDialog = true;
        this.entityData = {
          paid: false,
          access: true,
          scripts_enabled: false,
          time_before: 15,
          lang: 'ua',
          show_participants: false,
          status: 'draft'
        }
        this.step = 1
      },
      openDraftModalWindow(item) {
        this.isNewWebinar = false;
        this.displayDialog = true;
        this.step = 1;
        this.selectedWebinar = item;
      },

      async getAllManagers() {
        const result = await this.GET_ALL_MANAGERS();
        if (!result) {
          this.ERROR();
          return
        }

        this.managersList.push(result)
      },

      // crud webinars

      addWebinar(value) {
        this.step = 2;
        this.webinarItems.push(value);

        this.webinarItems = this.COPY(this.webinarItems);
      },
      editWebinar(item) {
        this.step = 2;
        const index = this.webinarItems.findIndex(el => el.uuid === item.uuid)

        if (index === -1) {
          this.ERROR();
          return
        }

        this.webinarItems[index] = item;

        this.webinarItems = this.COPY(this.webinarItems);
      },
      async deleteWebinar(item) {

        const result = await this.DELETE_WEBINARS(item);

        if (!result) {
          this.ERROR();
          return
        }

        // пересчитываю вебинары в категории
        this.updateCategoryList(item, '-');

        // удаляю вебинар из списка
        const index = this.webinarItems.findIndex(el => el.uuid === item.uuid);

        if (index === -1) {
          this.ERROR();
          return
        }
        this.webinarItems.splice(index, 1);

        this.notify(this.$t('webinar.notify.delete_webinar'), 'success', 'mdi-check-circle-outline')
        this.displayConfirmDeleteDialog = false;
        this.displayStopBroadcastDialog = false;
      },
      async copyWebinar(item) {
        const result = await this.COPY_WEBINAR(item);
        if (!result) {
          this.ERROR();
          return
        }

        this.webinarItems.push(result);

        // Обновляю категории, увеличиваю количествов вебинаров в категории
        this.updateCategoryList(item, '+');

        this.webinarItems = this.COPY(this.webinarItems);
        this.notify(this.$t('webinar.notify.copy_webinar'), 'success', 'mdi-check-circle-outline');
      },

      // crud categories
      async getWebinarsCategories() {
        const result = await this.GET_WEBINARS_CATEGORIES();
        if (!result) {
          this.ERROR();
          return
        }

        this.categorySelect = result.items;

        this.categorySelect.total = result.total_webinars;
      },
      async addEditWebinarsCategory() {
        if (this.newList) {
          // добавление категории
          const result = await this.ADD_WEBINARS_CATEGORY(this.listEntityData);
          if (!result) {
            this.ERROR();
            return
          }
          result.webinars = 0;

          this.categorySelect.push(result);
          this.categorySelect = this.COPY(this.categorySelect);

        } else {
          // редактирование категории
          const result = await this.EDIT_WEBINARS_CATEGORY(this.listEntityData);
          if (!result) {
            this.ERROR();
            return
          }

          let index = this.categorySelect.findIndex(el => el.value === result.value);
          if (index === -1) {
            this.ERROR();
            return
          }

          this.categorySelect[index].text = result.text;
          this.categorySelect = this.COPY(this.categorySelect);
        }

        this.displayListDialog = false;
      },
      async deleteWebinarsCategory() {
        // фильтр вебинаров, у которых есть эта категория
        const webinarsList = this.webinarItems.filter(item => item.categories.includes(this.listEntityData.value))

        // присваивание их uuid переменной, которая используется в функции removeCategoryFromWebinar
        this.selectedWebinars = webinarsList.map(el => el.uuid)

        // удаление категории у вебинара
        await this.removeCategoryFromWebinar();

        // удаление самой категории из списка
        const result = await this.DELETE_WEBINARS_CATEGORY(this.listEntityData)
        if (!result) {
          this.ERROR();
          return
        }

        const index =  this.categorySelect.findIndex(el => el.value === this.listEntityData.value);
        if (index === -1) {
          this.ERROR();
          return
        }

        this.categorySelect.splice(index, 1);
        this.displayListDialog = false;
        this.selectedCategory = 'all';
      },

      // crud webinars & categories
      async addWebinarsToCategory() {
        this.newCategory = true;

        const result = await this.ADD_WEBINARS_TO_CATEGORY({ webinars : this.selectedWebinars, uuid: this.categorySelectEntityData });
        if (!result) {
          this.ERROR();
          return
        }

        // добавляю категорию вебинарам
        let webinarsCount = 0;
        this.webinarItems.forEach(el => {
          if (this.selectedWebinars.includes(el.uuid) && !el.categories.includes(this.categorySelectEntityData)) {
            el.categories.push(this.categorySelectEntityData);
            webinarsCount += 1;
          }
        });

        // пересчитываю количество вебинаров в категории
        this.categorySelect.forEach(el => el.value === this.categorySelectEntityData ? el.webinars = el.webinars + webinarsCount: el.webinars);

        this.selectedWebinars = []
        this.displayAddToCategoryDialog = false;
        this.newCategory = false;
        this.categorySelectEntityData = '';
      },
      async removeCategoryFromWebinar() {
        const result = await this.DELETE_CATEGORY_FROM_WEBINAR({ webinars: this.selectedWebinars, uuid: this.selectedCategory});

        if (!result) {
          this.ERROR();
          return
        }

        // удаляю категорию у вебинаров
        this.webinarItems.forEach(el => {
          if (this.selectedWebinars.includes(el.uuid) ) {
            const index = el.categories.findIndex(value => value === this.selectedCategory)

            if (index === -1) {
              this.ERROR;
              return
            }

            el.categories.splice(index, 1);
          }
        })

        // пересчитываю количество вебинаров в категории
        const webinarsCount = this.selectedWebinars.length;
        this.categorySelect.forEach(el => el.value === this.selectedCategory ? el.webinars = el.webinars - webinarsCount: el.webinars);

        this.selectedWebinars = [];
        this.displayAddToCategoryDialog = false;
        this.newCategory = false;
      },


      //Technical

      // сортировка пунктов меню в выпадающем списке вебинара в зависимости от статуса
      editActionsSorted(item) {
        return this.editActions.filter(el => el.belongs.includes(item.status))
      },

      // обновление количества вебинаров в категории в зависимости от действий с вебинарами
      updateCategoryList(item, operation) {
        const allowedOperations = ['+', '-'];

        if (item.categories.length !== 0) {
          this.categorySelect.forEach(el => {
            if (item.categories.includes(el.value) && allowedOperations.includes(operation)) {
              operation === '+'
                ? el.webinars++
                : (el.webinars > 0 ? el.webinars-- : el.webinars)
            }
          })
        }
        this.categorySelect = this.COPY(this.categorySelect)
      },

      getAccessedPercent(item) {
        if (!item.participant) {
          return 0
        }
        return Math.round(((item.accessed || 0)/item.participant)*100)
      },

      returnColor(item) {
        switch (item.status) {
          case 'draft': return this.wsDARKLIGHT
          case 'planned': return this.wsSUCCESS
          case 'in_process': return this.wsWARNING
          default : return this.wsACCENT
        }
      },

      async initPage() {
        this.loading = true;
        const result = await this.GET_WEBINARS();
        this.loading = false;

        console.log('get webinars')
        console.log(result)

        if (!result) {
          this.ERROR();
          return
        }

        this.webinarItems = result.items.map(el => {
          return {
            ...el,
            participants: el.participants || 0,
            accessed: el.accessed || 0,
            joined: el.joined || 0,
            absent: el.absent || 0,
          }
        })

        await this.getWebinarsCategories();
        await this.getAllManagers();

      },
    },

    mounted() {
      this.initPage();
    }
  }
</script>

<style scoped lang="scss">


</style>
